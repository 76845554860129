<template>
  <div class="public_main_bgc pb30">
    <div class="public-activity-box">
      <div class="public-breadcrumb">
        <span class="breadcrumb-before">
          当前位置：
          <router-link to="/">首页</router-link> >
          <router-link to="/researchCenter?navActive=1">教研活动</router-link>
          >
        </span>
        <span class="breadcrumb-current">活动详情</span>
      </div>

      <div class="public-main-box link-main-box">
        <div class="link-body">
          <router-view :activityInfoData="detailData"></router-view>
        </div>
        <div class="link-catalog-box">
          <div class="link-head">
            <i class="title-line"></i>
            <h3 class="title-text">活动环节</h3>
          </div>
          <div class="link-module" v-for="(item, index) in list" :key="index">
            <div class="link-catalog">
              <div class="catalog-info" @click="catalogClick(index)">
                <i class="info-icon" :class="item.linkShow ? 'info-icon-open' : 'info-icon-close'"></i>
                <p class="info-title">{{ item.name }}</p>
              </div>
            </div>
            <div class="link-content" :id="`link-content-${index}`">
              <div class="link-item" :class="item1.active ? 'link-item-active' : ''"
                v-for="(item1, index1) in item.activity_link" :key="index1" @click="linkDetailsUrl(item1)">
                <div class="item-info">
                  <span class="info-order">{{ item1.order }}</span>
                  <i class="info-icon" :class="item1.type | typeIconFilter"></i>
                  <div class="info-content">
                    <h3 class="content-type">{{ item1.type_name }}</h3>
                    <i class="content-line"></i>
                    <p class="content-title">{{ item1.name }}</p>
                  </div>
                  <span class="info-state" :class="item1.status | stateFilter('class')">{{
            item1.status | stateFilter("name")
          }}</span>
                </div>
              </div>
              <CommonEmpty v-if="item.activity_link.length == 0" TextVal="暂无环节" hVal="110px"></CommonEmpty>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryString } from "@/utils/utils.js";
export default {
  components: {},
  data() {
    return {
      id: 0,
      activityId: 0,
      detailData: {},
      is_from: 1,
      list: [], //列表
    };
  },
  created() {
    const query = this.$route.query;
    this.id = query.id;
    this.activityId = query.activityId;
    this.is_from = query.is_from || 1;
    this.getDetail();
  },
  methods: {
    //环节详情链接
    linkDetailsUrl(e) {
      let path = "";
      let query = {
        id: e.id,
        activityId: this.activityId,
        is_from: this.is_from,
      };
      switch (e.type) {
        //资源浏览环节
        case 1:
          path = "resourcebrowsing";
          break;
        //资源上传环节
        case 2:
          path = "resourceuploadbrowsing";
          break;
        //讨论环节
        case 3:
          path = "discussbrowsing";
          break;
        //问卷环节
        case 4:
          path = "questionbrowsing";
          break;
        //投票环节
        case 5:
          path = "votebrowsing";
          break;
        //协作文档环节
        case 6:
          path = "collaborationbrowsing";
          break;
        //在线直播环节
        case 7:
          path = "livebrowsing";
          break;
        //教研会议环节
        case 8:
          path = "meetbrowsing";
          break;
        //在线文档环节
        case 9:
          path = "OnLineWord";
          break;
      }
      let url = `/${path}${queryString(query)}`
      this.$router.push({
        path: url
      })
      setTimeout(() => {
        this.$router.go(0)
      }, 500);
    },
    // 请求后台获取活动详情
    getDetail() {
      //教研组活动、个人活动
      if (this.is_from == 1) {
        this.$axios.get(`activity/${this.activityId}`).then((res) => {
          this.detailData = res.data.data;
          this.setActivityIsJoin();
          this.setActivityIsEnable();
        });
      } else {
        this.$axios
          .get(`index/teachingStudioActivity/info`, {
            params: { id: this.activityId },
          })
          .then((res) => {
            this.detailData = res.data.data;
            this.setActivityIsJoin();
            this.setActivityIsEnable();
          });
      }
    },
    //设置加入权限
    setActivityIsJoin() {
      let state = "";
      if (this.is_from == 1) {
        state =
          this.detailData.checkUserJoin == 1 || this.detailData.checkUserJoin == 4
            ? 1
            : 0;
      }
      if (this.is_from == 2) {
        state =
          this.detailData.join_permission.hasOwnProperty("state") &&
            this.detailData.join_permission.state == 1
            ? 1
            : 0;
      }
      localStorage.setItem("activity_isJoin", state);
      this.$store.commit("setActivity_isJoin", state);

      this.getList();
    },
    //设置研修活动是否启用
    setActivityIsEnable() {
      let state =
        this.detailData.audit_status == 4 || this.detailData.setup_status == 2 ? 1 : 0;
      localStorage.setItem("activity_isEnable", state);
      this.$store.commit("setActivity_isEnable", state);
    },
    //环节模块点击
    catalogClick(index) {
      this.list[index].linkShow = !this.list[index].linkShow;
      let linkContent = document.getElementById(`link-content-${index}`);
      if (this.list[index].linkShow) {
        linkContent.style.display = "block";
      } else {
        linkContent.style.display = "none";
      }
    },
    //获取列表
    getList() {
      this.$axios
        .get("activityChapter/get_list", {
          params: { activity_id: this.activityId, is_personid: this.is_from },
        })
        .then((res) => {
          const data = res.data;
          if (data.code == 900) {
            let list = data.data.data;
            if (list.length > 0) {
              let order = 0;
              list.forEach((item) => {
                item.linkShow = true;
                if (item.activity_link.length > 0) {
                  item.activity_link.forEach((item1) => {
                    if (item1.id == this.id) {
                      item1.active = true;
                    } else {
                      item1.active = false;
                    }
                    order++;
                    item1.order = `${order}.`;
                  });
                }
              });
            }
            this.list = list;
          }
        });
    },
  },
  filters: {
    //环节类型图标
    typeIconFilter(type) {
      return `info-icon-${type}`;
    },
    //环节状态
    stateFilter(state, type) {
      let value = "";
      if (type == "name") {
        if (state == 1) {
          value = "未开始";
        }
        if (state == 2) {
          value = "进行中";
        }
        if (state == 3) {
          value = "已结束";
        }
      }
      if (type == "class") {
        if (state == 1) {
          value = "info-state-started";
        }
        if (state == 2) {
          value = "info-state-ing";
        }
        if (state == 3) {
          value = "info-state-end";
        }
      }
      return value;
    },
  },
  watch: {
    $route: {
      handler(newVal, oldval) {
        if (this.list.length > 0) {
          this.list.forEach((item) => {
            if (item.activity_link.length > 0) {
              item.activity_link.forEach((item1) => {
                if (item1.id == newVal.query.id) {
                  item1.active = true;
                } else {
                  item1.active = false;
                }
              });
            }
          });
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.public-activity-box {
  width: 1400px;
  margin: auto;
}

.public-breadcrumb {
  height: 48px;
  line-height: 48px;

  .breadcrumb-before {
    font-size: 14px;
    color: #666;

    a {
      font-size: 14px;
      color: #666;
    }
  }

  .breadcrumb-current {
    font-size: 14px;
    color: #ff8201;
  }
}

.public-main-box {}

.link-main-box {
  display: flex;

  .link-body {
    flex: 1;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;

    .study-active-introduce {
      width: 100%;
    }
  }

  .link-catalog-box {
    width: 390px;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    margin-left: 10px;

    .link-head {
      height: 56px;
      border-bottom: 1px solid #d8e0f2;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .title-line {
        width: 4px;
        height: 18px;
        background: #3489fe;
        display: block;
        margin: 0 8px 0 20px;
      }

      .title-text {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 18px;
      }
    }

    .link-module {
      padding: 0 20px 30px 20px;

      .link-catalog {
        display: flex;

        .catalog-info {
          flex: 1;
          overflow: hidden;
          display: flex;
          align-items: center;

          .info-icon {
            width: 20px;
            height: 20px;
            background-size: 100% !important;
            display: block;
            margin-right: 8px;
          }

          .info-icon-close {
            background: url("../../../assets/images/icon-close.png") no-repeat center;
          }

          .info-icon-open {
            background: url("../../../assets/images/icon-open.png") no-repeat center;
          }

          .info-title {
            flex: 1;
            overflow: hidden;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .link-content {
        padding-top: 18px;

        .link-item {
          height: 50px;
          padding: 0 10px;
          background: #f4f7ff;
          border-radius: 4px;
          display: flex;
          align-items: center;
          margin: 0 0 10px 0;
          cursor: pointer;

          .item-info {
            flex: 1;
            overflow: hidden;
            display: flex;
            align-items: center;

            .info-order {
              font-size: 16px;
              color: #3489fe;
              font-weight: bold;
              margin-right: 8px;
            }

            .info-icon {
              width: 20px;
              height: 20px;
              background-size: 100% !important;
              display: block;
              margin-right: 8px;
            }

            .info-icon-1 {
              background: url("../../../assets/huodon_img/资源浏览@2x.png") no-repeat center;
            }

            .info-icon-2 {
              background: url("../../../assets/huodon_img/资源上传@2x.png") no-repeat center;
            }

            .info-icon-3 {
              background: url("../../../assets/huodon_img/讨论@2x.png") no-repeat center;
            }

            .info-icon-4 {
              background: url("../../../assets/huodon_img/问卷@2x.png") no-repeat center;
            }

            .info-icon-5 {
              background: url("../../../assets/huodon_img/投票@2x.png") no-repeat center;
            }

            .info-icon-6 {
              background: url("../../../assets/huodon_img/在线文档@2x.png") no-repeat center;
            }

            .info-icon-7 {
              background: url("../../../assets/huodon_img/在线直播@2x.png") no-repeat center;
            }

            .info-icon-8 {
              background: url("../../../assets/huodon_img/教研会议@2x.png") no-repeat center;
            }

            .info-content {
              flex: 1;
              overflow: hidden;
              display: flex;
              align-items: center;
              margin-right: 10px;

              .content-type {
                font-size: 16px;
                font-weight: bold;
                color: #333333;
              }

              .content-line {
                width: 1px;
                height: 16px;
                background: #333333;
                display: block;
                margin: 0 10px;
              }

              .content-title {
                flex: 1;
                overflow: hidden;
                font-size: 16px;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .info-state {
              width: 50px;
              height: 20px;
              border-radius: 10px 10px 10px 0;
              font-size: 12px;
              color: #ffffff;
              line-height: 20px;
              text-align: center;
              white-space: nowrap;
            }

            .info-state-started {
              background: #3489fe;
            }

            .info-state-ing {
              background: #ff9400;
            }

            .info-state-end {
              background: #cccccc;
            }
          }
        }

        .link-item-active {
          background: #d0ddff;
        }
      }
    }
  }
}
</style>
